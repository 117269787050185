import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

type MyCoAuthLayoutProps = {
    children: React.ReactNode;
};

const MyCoAuthLayout: React.FC<MyCoAuthLayoutProps> = (props: any) => {
    const { children } = props;
    return (
        <ThemeProvider theme={theme}>
            <Container
                component="main"
                maxWidth="xl"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: { xs: 2, sm: 2, md: 3, lg: 3, xl: 4 },
                    alignItems: "center",
                    justifyContent: "center",
                    height: { md: "100vh", lg: "100vh", xl: "100vh" },
                    paddingTop: { xs: 2, sm: 2 },
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    className="signin-box"
                >
                    {children}
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default MyCoAuthLayout;
