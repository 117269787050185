import "./assets/style.scss";
import HomeComponent from "./pages/home/components/home.component";

function App() {
  return (
    <div className="App">
      <HomeComponent />
    </div>
  );
}

export default App;
