import { Button, ButtonProps, Tooltip } from "@mui/material";
import { ReactNode } from "react";

interface Props {
    /**
     * Libellé du bouton
     */
    label?: string;
    /**
     * Contenu du tooltip
     */
    tooltipTitle?: string;
    /**
     * Position du tooltip
     */
    tooltipPlacement?: "top" | "right" | "bottom" | "left";
    /**
     * ID du bouton pour les tests
     */
    dataTestId?: string;
    /**
     * Contenu du bouton
     */
    children?: ReactNode;
}

export type MyCoButtonProps = Props & ButtonProps;

/**
 * Bouton de base pour les espaces MyConnecting
 * @param param0 props
 * @returns JSX element
 */
const MyCoButton: React.FC<MyCoButtonProps> = ({ label, tooltipTitle, tooltipPlacement, dataTestId, children, ...props }) => {
    return (
        <Tooltip title={tooltipTitle ?? ""} placement={tooltipPlacement ?? "bottom"} arrow>
            <span>
                <Button variant="outlined" data-testid={dataTestId ?? "button-data-testid"} {...props}>
                    {children ?? label ?? "Bouton"}
                </Button>
            </span>
        </Tooltip>
    );
};

export default MyCoButton;
