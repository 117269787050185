import { Box, Grid, Typography } from "@mui/material";
import MyCoAuthLayout from "../../../shared/components/Layout/MyCoAuthLayout";
import { BASE_COLOR } from "../../../shared/constants/color.base";
import ArrowRight from "./arrow.component";
import mainLogo from "./../../../assets/images/logos/Logo_MyConnecting.png";
import { useMemo } from "react";
import useWindowDimensions from "../../../shared/hooks/use-window-dimensions";
import MyCoButton from "../../../shared/components/Button/Base/MyCoButton";

interface Props {}

const HomeComponent: React.FC<Props> = (props) => {
  const { width } = useWindowDimensions();
  const isSmallScreen = useMemo(() => width < 900, [width]);

  return (
    <MyCoAuthLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography
            variant="h3"
            sx={{ color: BASE_COLOR.orange, fontWeight: "bolder" }}
          >
            ACCÉDER À
          </Typography>
          <Typography
            variant="h3"
            sx={{ color: BASE_COLOR.orange, fontWeight: "bolder" }}
          >
            NOS ESPACES
          </Typography>
          <ArrowRight
            size={10}
            borderSize={2}
            barWidth={2}
            barHeight={330}
            color={BASE_COLOR.purple}
            direction="right"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container spacing={2} justifyContent="center">
            {[
              {
                label: "Espace apprenant",
                title: "Vous êtes Apprenant ?",
                subtitle: "Cliquez ici",
                url: "https://apprenant.groupemyconnecting.com",
                tooltip: "Espace apprenant",
                Children: true,
              },
              {
                label: "Espace client",
                title: "Vous êtes Responsable formation ?",
                subtitle: "Cliquez ici",
                url: "https://client.groupemyconnecting.com",
                tooltip: "Espace client",
                Children: true,
              },
              {
                label: "MYCONNECTING",
                url: "https://www.myconnecting.fr/",
                tooltip: "MYCONNECTING",
              },
              {
                label: "MYCONNECTING-IA",
                url: "https://myconnecting-ia.com/",
                tooltip: "MYCONNECTING-IA",
              },
            ].map((button, index) => (
              <Grid
                item
                xs={isSmallScreen ? 12 : 12}
                md={isSmallScreen ? 12 : 3}
                key={index}
                display="flex"
                justifyContent="center"
                alignItems={"center"}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems={"center"}
                  width="100%"
                  height="100%"
                  minWidth="325px"
                  minHeight="70px"
                >
                  <MyCoButton
                    label={button?.Children ? "" : button.label}
                    children={
                      button?.Children ? (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              {button?.title}
                            </Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="body2">
                              {button?.subtitle}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : undefined
                    }
                    onClick={() => window.open(button.url, "_blank")}
                    size="medium"
                    sx={{
                      width: "100%",
                      height: "100%",
                      minWidth: "325px",
                      minHeight: "70px",
                      color: BASE_COLOR.white,
                      backgroundColor: BASE_COLOR.primary,
                      "&:hover": {
                        color: BASE_COLOR.white,
                        backgroundColor: BASE_COLOR.primary,
                      },
                    }}
                    tooltipTitle={button.tooltip}
                  />
                </Box>
              </Grid>
            ))}
            {/* <Box display="flex" flexDirection="column" alignItems="center">
                <Card
                  sx={{
                    width: isSmallScreen ? 150 : 180,
                    height: isSmallScreen ? 150 : 180,
                    backgroundColor: BASE_COLOR.lightWhiteSmoke,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    window.open("https://www.myconnecting.fr/", "_blank");
                  }}
                ></Card>
                <Box
                  onClick={() => {
                    window.open("https://www.myconnecting.fr/", "_blank");
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      mt: 1,
                      cursor: "pointer",
                    }}
                  >
                    {"www.myconnecting.fr"}
                  </Typography>
                </Box>
              </Box> */}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Typography
            component="img"
            src={mainLogo}
            sx={{ width: "25%", mt: 5 }}
          ></Typography>
        </Grid>
      </Grid>
    </MyCoAuthLayout>
  );
};

export default HomeComponent;
