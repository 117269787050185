import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/system';
import { rotate } from '../utils/rotate';

interface ArrowProps {
  size?: number;
  borderSize?: number;
  barWidth?: number;
  barHeight?: number;
  color?: string;
  direction?: 'top' | 'right' | 'bottom' | 'left';
}

const ArrowContainer = styled(Box)<ArrowProps>(({ size, borderSize }) => ({
  display: 'inline-block',
  height: size || 20,
  position: 'relative',
  width: size || 20,
  top: 0,
  left: 320,
  '&::after': {
    borderBottomStyle: 'solid',
    borderBottomWidth: borderSize || 3,
    borderRightStyle: 'solid',
    borderRightWidth: borderSize || 3,
    content: '""',
    display: 'inline-block',
    height: size || 20,
    left: -1,
    position: 'absolute',
    top: -1,
    width: size || 20,
  },
}));

const ArrowBar = styled(ArrowContainer)<ArrowProps>(({ barHeight, barWidth, color }) => ({
  '&::before': {
    bottom: 1,
    content: '""',
    height: barHeight || 25,
    position: 'absolute',
    right: 0,
    transformOrigin: 'bottom',
    width: barWidth || 2,
    backgroundColor: color || 'black',
    ...rotate(-45),
  },
}));

const ArrowRight: React.FC<ArrowProps> = ({ size, borderSize, barWidth, barHeight, color, direction }) => {
  const rotationMap = {
    top: 225,
    right: 315,
    bottom: 45,
    left: 150,
  };

  return (
    <ArrowBar size={size} borderSize={borderSize} barWidth={barWidth} barHeight={barHeight} color={color} sx={rotate(rotationMap[direction || 'right'])} />
  );
};

export default ArrowRight;