export const BASE_COLOR = {
    primary: "#4E006E",
    secondary: "#FFFFFF",
    purple: "#4e006e",
    black: "#374151",
    gray: "#f7f7f7",
    grey: "#cccccc",
    text: "#000000de",
    green: "#14be38",
    white: "#ffffff",
    lightWhiteSmoke: "#f4f4f4",
    // purple shade
    indigo: "#4E006E",
    electricLavender: "#ECBFFF",
    psychedelicPurple: "#C740FF",
    darkViolet: "#8800BF",
    russianViolet: "#2D0040",
    peacockBlue: "#005064",
    // orange shade
    orange: "#FF4B00",
    tangelo: "#FF4B00",
    apricot: "#FFD2BF",
    coral: "#FF825A",
    burntOrange: "#FF7840",
    scarlet: "#E83700",
    turkeyRed: "#AF0F00",
    yellow: "#ffea00",
};

export const TRAINER_COLOR = {
    black: "#374151",
    // purple shade
    indigo: "#4E006E",
    electricLavender: "#ECBFFF",
    psychedelicPurple: "#C740FF",
    darkViolet: "#8800BF",
    russianViolet: "#2D0040",
    peacockBlue: "#005064",
    // orange shade
    tangelo: "#FF4B00",
    apricot: "#FFD2BF",
    coral: "#FF825A",
    burntOrange: "#FF7840",
    scarlet: "#E83700",
    turkeyRed: "#AF0F00",
};
